<template>
  <div>
    <!-- HEADER -->
    <div class="d-flex justify-content-end align-items-center mb-4">
      <template v-if="campaign && campaign.status !== 'FINISHED'">
        <Button
          class="btn btn-sm btn-primary me-2"
          v-b-modal="'confirm-campaign-edit-modal'"
        >
          {{ $t('edit_campaign') }}
        </Button>
        <ConfirmCampaignEditModal
          :campaign="campaign"
        />
      </template>
      <template v-if="campaign.status === 'DRAFT'">
        <Button
          class="btn-sm btn-color1 me-2"
          v-b-modal.destroy-campaign-modal
        >
          {{ $t('delete') }}
        </Button>
        <DestroyCampaignModal
          :campaign="campaign"
          @campaign-destroyed="handleDestroyed"
        />
      </template>
      <template v-if="campaign.status !== 'FINISHED'">
        <template v-if="campaign.status === 'ACTIVE'">
          <Button
            class="btn-sm btn-color1 me-2"
            v-b-modal.pause-campaign-modal
          >
            {{ $t('pause') }}
          </Button>
          <PauseCampaignModal
            :campaign="campaign"
            @campaign-paused="getCampaign"
          />
        </template>
        <template v-else-if="campaign.status === 'PAUSED'">
          <Button
            class="btn-sm btn-color1 me-2"
            v-b-modal.activate-campaign-modal
          >
            {{ $t('enable') }}
          </Button>
          <ActivateCampaignModal
            :campaign="campaign"
            @campaign-activated="getCampaign"
          />
        </template>
      </template>
      <Button
        class="btn-sm btn-color1 me-2"
        @click="replicate"
        :loading="loader"
      >
        {{ $t('clone_campaign') }}
      </Button>
      <router-link :to="{ name: 'campaigns.index' }" class="btn btn-sm btn-primary">{{ $t('back') }}</router-link>
    </div>
    <h1 class="highlighted-color1 font-size-large">
      <span>{{ campaign.name }}</span>
    </h1><!-- END HEADER -->
    <!-- CAMPAIGN -->
    <div class="mt-4">
      <!-- MESSAGES -->
      <div
        class="mb-4 error-message-card text-white font-size-small p-3"
        v-if="hasMessage"
      >
        <p
          class="mb-0"
          v-if="campaign.status === 'ERROR'"
        >
          {{ $t('error_creating_campaign') }}
          <span>
            <RetryCampaignCreationOnPlatformButton
              :campaignId="campaign.id"
              @retry-successful="getCampaign"
              @retry-error="handleRetryError"
              color="white"
            />
          </span>
        </p>
        <p
          class="mb-0"
          v-if="campaign.status === 'FINISHED' || campaign.status === 'PAUSED'"
        >
          {{ $t('message_consolidate_metrics') }}
        </p>
        <p
          class="mb-0"
          v-if="campaign.available_amount > campaign.amount"
        >
          {{ $t('message_campaigns_with_newly_adjusted_balance') }}
        </p>
      </div> <!-- END MESSAGES -->

      <!-- PROCESSING -->
      <div
        class="mb-4 alert-message-card font-size-small p-3"
        v-if="campaign.status === 'PENDING'"
      >
        <p
          class="mb-0"
        >
          <span class="me-1">
            <i class="fa fa-clock-o"></i><i class="fa fa-spin fa-spinner ms-1"></i>
          </span>
          {{ $t('your_campaign_is_being_processed') }}
        </p>
      </div> <!-- PROCESSING -->

      <!-- BIG NUMBERS -->
      <div class="mb-5">
        <h2 class="highlighted-color1 font-size-medium">
          <span>{{ $t('performance') }}</span>
        </h2>
        <!-- FILTER -->
        <div class="mb-4">
          <div class="d-flex text-center align-items-end justify-content-center justify-content-lg-end font-size-small default-form">
            <template>
              <div class="d-flex">
                <div style="margin-right: 1em; display: flex; align-items: flex-start; flex-direction: column; ">
                  <label class="form-label" style="font-weight: bold; margin-right: 10px;">{{ $t('start_date') }}</label>
                  <input type="text" class="form-control" :value="formattedDateStart" readonly>
                </div>
                <div style="margin-right: 1em; display: flex; align-items: flex-start; flex-direction: column; ">
                  <label style="font-weight: bold;" class="form-label fw-bold">{{ $t('end_date') }}</label>
                  <input class="form-control" :value="formattedDateEnd" readonly>
                </div>
              </div>
            </template>
            <DateRangeInput
              v-model.trim="filters.summaryDate"
              :startPeriod="campaign.start_date"
              :endPeriod="campaign.end_date"
            />
          </div>
        </div> <!-- END FILTER -->
        <div v-if="summaryLoader" class="text-center">
          <Loader
            :size="'big'"
          />
        </div>
        <CampaignSummary
          :data="summary"
          :currency="campaign.advertiser.currency"
          v-else-if="summary"
        />
      </div> <!-- END BIG NUMBERS -->
      <!-- TABS -->
      <b-tabs content-class="mt-4" nav-class="default-tabs">
        <!-- OVERVIEW TAB -->
        <b-tab :title="$t('general_data')">
          <div class="mb-5">
            <h2 class="highlighted-color1 font-size-medium mb-4">
              <span>{{ $t('general_data') }}</span>
            </h2>
            <div class="card mb-3">
              <div class="card-body">
                <p>
                  <strong>{{ $t('advertiser') }}:</strong> <router-link :to="{ name: 'advertisers.show', params: { id: campaign.advertiser.id } }" class="link-color3">{{ campaign.advertiser.name }}</router-link><br/>
                  <strong>{{ $t('status') }}:</strong> {{ campaign.status | formatCampaignStatus }}<br/>
                  <strong>{{ $t('start') }}:</strong> <span v-if="campaign.start_date">{{ campaign.start_date | formatDate }}</span><span v-else>{{ $t('not_defined') }}</span><br/>
                  <strong>{{ $t('end') }}:</strong> <span v-if="campaign.end_date">{{ campaign.end_date | formatDate }}</span><span v-else>{{ $t('not_defined') }}</span><br/>
                  <strong>{{ $t('budget') }}:</strong> {{ campaign.amount / 100 | formatMoney(campaign.advertiser.currency) }}<br/>
                  <strong>{{ $t('cpm_goal') }}:</strong> {{ campaign.cpm_target_amount / 100 | formatMoney(campaign.advertiser.currency) }}<br/>
                  <strong>{{ $t('cpv_goal') }}:</strong> {{ campaign.cpv_target_amount / 100 | formatMoney(campaign.advertiser.currency) }}<br/>
                  <strong>{{ $t('spending_to_date') }}:</strong> {{ campaign.spending / 100 | formatMoney(campaign.advertiser.currency) }}<br/>
                  <strong>{{ $t('total_impressions') }}:</strong> {{ campaign.impressions | formatNumber }}<br/>
                  <strong>{{ $t('total_views') }}:</strong> {{ campaign.complete_views | formatNumber }}<br/>
                  <strong>{{ $t('total_conversions') }}:</strong> {{ campaign.conversions | formatNumber }}<br/>
                  <strong>{{ $t('vtr') }}:</strong> {{ campaign.vtr | formatNumber }}%<br/>
                  <strong>{{ $t('cpm') }}:</strong> {{ campaign.cpm / 100 | formatMoney(campaign.advertiser.currency) }}<br/>
                  <strong>{{ $t('cpa') }}:</strong> {{ campaign.cpa / 100 | formatMoney(campaign.advertiser.currency) }}
                </p>
              </div>
            </div>
            <h2 class="highlighted-color1 font-size-medium mb-4">
              <span>{{ $t('locations') }}</span>
            </h2>
            <div class="card mb-3">
              <div class="card-body">
                <h2 class="highlighted-color1 font-size-regular mb-3">
                  <span>{{ $t('states') }}</span>
                </h2>
                <template v-if="campaign.states.length > 0">
                  <ul class="mb-0">
                    <li
                      v-for="(state, index) in campaign.states"
                      :key="index"
                    >
                      {{ state.name }}
                    </li>
                  </ul>
                </template>
                <template v-else>
                  <p>{{ $t('no_states_selected') }}</p>
                </template>

                <h2 class="highlighted-color1 font-size-regular mb-3 mt-4">
                  <span>{{ $t('cities') }}</span>
                </h2>
                <template v-if="campaign.cities.length > 0">
                  <ul class="mb-0">
                    <li
                      v-for="(city, index) in campaign.cities"
                      :key="index"
                    >
                      {{ city.name }} ({{ city.state_short_name }})
                    </li>
                  </ul>
                </template>
                <template v-else>
                  <p>{{ $t('no_cities_selected') }}</p>
                </template>
              </div>
            </div>
            <h2 class="highlighted-color1 font-size-medium mb-4">
              <span>{{ $t('browsers') }}</span>
            </h2>
            <div class="card mb-3">
              <div class="card-body">
                <template v-if="campaign.browser.length > 0">
                  <ul class="mb-0">
                    <li
                      v-for="(browser, index) in campaign.browser"
                      :key="index"
                    >
                      {{ browser }}
                    </li>
                  </ul>
                </template>
                <template v-else>
                  <p>{{ $t('no_browsers_selected') }}</p>
                </template>
              </div>
            </div>
            <h2 class="highlighted-color1 font-size-medium mb-4">
              <span>{{ $t('audiences2') }}</span>
            </h2>
            <div class="card mb-5">
              <div class="card-body">
                <template v-if="campaign.audiences.length > 0">
                  <ul class="mb-0">
                    <li
                      v-for="(audience, index) in campaign.audiences"
                      :key="index"
                    >
                      {{ audience.name }}
                    </li>
                  </ul>
                </template>
                <template v-else>
                  <p>{{ $t('no_audiences_selected') }}</p>
                </template>
              </div>
            </div>
          </div>
        </b-tab> <!-- END OVERVIEW TAB -->
        <!-- CREATIVES TAB -->
        <b-tab :title="$t('creatives')">
          <div>
            <h2 class="highlighted-color1 font-size-medium mb-4">
              <span>{{ $t('creatives') }}</span>
            </h2>
            <CreativeListTable
              :creatives="creatives"
              :format="campaign.type"
            />
          </div>
        </b-tab> <!-- END CREATIVES TAB -->
        <!-- TRACKING TAB -->
        <b-tab :title="$t('tracking')">
          <div>
            <h2 class="highlighted-color1 font-size-medium mb-4">
              <span>{{ $t('tracking') }}</span>
            </h2>
            <PixelRuleListTable
              :rules="campaign.pixels"
              :platform="campaign.platform"
              :advertiser="campaign.advertiser"
            />
          </div>
        </b-tab> <!-- END TRACKING -->
      </b-tabs> <!-- END TABS -->
    </div> <!-- END CAMPAIGN -->
  </div>
</template>

<script>

import CampaignsService from '@/modules/campaigns/services/campaigns-service'
import { mapActions, mapState } from 'vuex'
import moment from 'moment'
import variables from '@/mixins/variables'
import NotificationService from '@/modules/notifications/services/notifications-service'

import ActivateCampaignModal from '@/components/campaigns/ActivateCampaignModal'
import Button from '@/components/common/Button/Button'
import CampaignSummary from '@/components/campaigns/Youtube/CampaignSummary'
import CreativeListTable from '@/components/creatives/CreativeListTable'
import DateRangeInput from '@/components/common/Input/DateRangeInput'
import DestroyCampaignModal from '@/components/campaigns/DestroyCampaignModal'
import Loader from '@/components/common/Loader/Loader'
import PauseCampaignModal from '@/components/campaigns/PauseCampaignModal'
import PixelRuleListTable from '@/components/pixels/PixelRuleListTable'
import RetryCampaignCreationOnPlatformButton from '@/components/campaigns/RetryCampaignCreationOnPlatformButton'
import ConfirmCampaignEditModal from '@/components/campaigns/Modal/ConfirmCampaignEditModal.vue'

export default {
  props: ['baseCampaign'],
  mixins: [variables],
  components: {
    ActivateCampaignModal,
    Button,
    CampaignSummary,
    ConfirmCampaignEditModal,
    CreativeListTable,
    DateRangeInput,
    DestroyCampaignModal,
    Loader,
    PauseCampaignModal,
    PixelRuleListTable,
    RetryCampaignCreationOnPlatformButton
  },
  data () {
    return {
      campaign: null,
      filters: {
        status: '',
        summaryDate: '',
        summaryStart: '',
        summaryEnd: ''
      },
      loader: false,
      summary: null,
      summaryLoader: true
    }
  },
  created () {
    this.campaign = this.baseCampaign
    this.filters.summaryStart = moment({ hour: 0, minute: 0, seconds: 0 }).subtract(90, 'days').format()
    this.filters.summaryEnd = moment({ hour: 0, minute: 0, seconds: 0 }).format()
    this.getCampaignSummary()
  },
  watch: {
    'filters.summaryDate': function (newValue, oldValue) {
      if (!newValue) return

      const newStart = moment(newValue.startDate)
      const oldStart = moment(oldValue.startDate)
      const newEnd = moment(newValue.endDate)
      const oldEnd = moment(oldValue.endDate)

      this.filters.summaryStart = newValue.startDate
      this.filters.summaryEnd = newValue.endDate

      if (!newStart.isSame(oldStart) || !newEnd.isSame(oldEnd)) {
        this.getCampaignSummary()
      }
    }
  },
  computed: {
    ...mapState('users', {
      user: state => state.user
    }),
    ...mapState('campaigns', {
      currentCampaign: state => state.currentCampaign
    }),
    /**
     * Creatives
     */
    creatives () {
      let array = []
      if (this.campaign.creatives.length > 0) array = [...array, ...this.campaign.creatives]
      return array
    },
    /**
     * Check if has message to show user
     */
    hasMessage () {
      if (!this.campaign) return false
      return this.campaign.status === 'FINISHED' || this.campaign.status === 'PAUSED' || this.campaign.status === 'ERROR' || this.campaign.available_amount > this.campaign.amount
    },
    formattedDateStart () {
      let dateValue = this.filters.summaryStart

      if (typeof dateValue === 'string') {
        dateValue = new Date(dateValue)
      }

      if (dateValue instanceof Date && !isNaN(dateValue)) {
        const [year, month, day] = dateValue.toISOString().split('T')[0].split('-')

        return `${day}/${month}/${year}`
      }

      return ''
    },
    formattedDateEnd () {
      let dateValue = this.filters.summaryEnd
      if (typeof dateValue === 'string') {
        dateValue = new Date(dateValue)
      }
      if (dateValue instanceof Date && !isNaN(dateValue)) {
        const [year, month, day] = dateValue.toISOString().split('T')[0].split('-')

        return `${day}/${month}/${year}`
      }

      return ''
    }
  },
  methods: {
    ...mapActions('campaigns', [
      'setCurrentCampaign'
    ]),
    /**
     * Get advertiser
     */
    async getCampaign () {
      this.loader = true
      try {
        const campaign = await CampaignsService.getCampaign(this.$route.params.id)
        this.campaign = campaign
        this.setCurrentCampaign({ campaign: campaign })
        this.getCampaignSummary()
      } catch (error) {
        error.handleGlobally && error.handleGlobally()
        this.$router.push('/')
      }
      this.loader = false
    },
    /**
     * Get campaign summary
     */
    async getCampaignSummary () {
      this.summaryLoader = true
      try {
        const summary = await CampaignsService.getCampaignsSummary({
          campaignIds: [this.campaign.id],
          type: this.campaign.type,
          startDate: moment(this.filters.summaryStart).format('YYYY-MM-DD'),
          endDate: moment(this.filters.summaryEnd).format('YYYY-MM-DD')
        })
        this.summary = summary
      } catch (error) {
        error.handleGlobally && error.handleGlobally()
      }
      this.summaryLoader = false
    },
    /**
     * Handle destroyed
     */
    handleDestroyed () {
      this.$router.push({ name: 'campaigns.index' })
    },
    /**
     * Handle retry error
     */
    handleRetryError (error) {
      error.handleGlobally && error.handleGlobally()
    },
    /**
     * Replicate campaign
     */
    async replicate () {
      this.loader = true
      try {
        const campaign = await CampaignsService.replicateCampaign(this.campaign.id)
        const notification = {
          notification: {
            type: 'success',
            content: this.$t('campaign_successfully_cloned')
          }
        }
        NotificationService.createGlobalSuccessNotification(notification)
        this.$router.push({ name: 'campaigns.show', params: { id: campaign.id } })
        setTimeout(() => {
          this.$router.go(0)
        }, 3000)
      } catch (error) {
        error.handleGlobally && error.handleGlobally()
      }
      this.loader = false
    }
  }
}
</script>

<style lang="scss" scoped>
.wrap-text {
  width: 100%;
  word-wrap: break-word;
}
</style>
